import React from 'react'
import './hero.css'
import { FaRegClock } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import HomeSchema from '../../../schema/HomeSchema';

const Hero = () => {
  return (
    <div className='klt_hero'>
      <div className='klt_hero_row'>
        <div className='klt_hero_col hero_details'>
            <h1><b>Best Towing Service at Langley Drive PMB 1399 Lawrenceville, GA 30046</b></h1>
            <h2>
                Leading <span className='klt_colored_text'>Towing</span> Service Provider.
            </h2>
            <HomeSchema />
            <p>
                At KL Towing, we understand that when you're stranded on the roadside, you need prompt, and reliable assistance. With years of experience serving <b className='klt_colored_text' >Georgia</b> and surrounding areas, we are your go-to towing service for any roadside emergency.
            </p>
            <div className='klt_cta_btns'>
                <a href='tel:+14704756564'>
                    <button className='klt_cta_btn red'>CALL US NOW!</button>
                </a>
                <Link to="/about">
                <button className='klt_cta_btn'  style={{ backgroundColor: 'black' }}>LEARN MORE</button>
                </Link>
            </div>
            <p><span><FaRegClock color='green'/></span> Available 24/7 for emergency road services.</p>
        </div>
        {/* <div className='klt_hero_col video_section bg_img'> */}
            {/* <a>
                <span>
                    <FaPlay className='play_icon' color='white' size={25} />
                </span>
            </a> */}
            {/* <div className='klt_info_box'>
                <a>
                    <p>24/7 EMERGENCY TOWING</p>
                    <p className='klt_info_box_phone'>+1-703-338-5304</p>
                    <p>
                        <a href='tel:+17033385304'>
                            CALL NOW!!
                        </a>
                    </p>
                </a>
            </div> */}
        {/* </div> */}
      </div>
    </div>
  )
}

export default Hero
