import React from 'react'
import { Route, Routes } from "react-router"
import Home from './pages/home/Home'
import Contact from './pages/contact/Contact'
import About from './pages/about/About'
import Blog from './pages/blog/Blog'
import Gallery from './pages/gallery/Gallery'
import BlogPost from './pages/blog/sections/BlogPost'
import Admin from './pages/admin/Admin'

const AllRoutes = () => {
  return (
    <div className='main'>
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/about' element={<About />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/blog' element={<Blog />} />
            <Route path="blog/:id" element={<BlogPost />} />
            <Route path="admin" element={<Admin />} />
        </Routes>
    </div>
  )
}

export default AllRoutes
